<template>
  <div class="reportlist">
    
    <div>
    <div v-if="loading" class="we-flex-col we-flex-center we-wrap-mt">
        <van-loading type="spinner" />
      </div>
    <div v-else>
      <div v-if="list.length == 0">
        <van-empty description="无记录" />
      </div>
      <div v-else>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
            <template>
              <div v-for="item in list" :key="item.id">
                <div class="reporttitle">
                  <div class="we-weight">体质辨识</div>
                  <div>{{item.constitution}}</div>
                </div>
                <div class="we-flex-row we-text-p15 we-justify-content">
                  <div>{{moment(item.createTime).format('YYYY-MM-DD')}}</div>
                  <div>
                    <van-button
                      type="primary"
                      size="small"
                      @click="$router.push({name:'analysisdetail',params:{id:item.id}})"
                    >查看详情</van-button>
                  </div>
                </div>

                <div class="addline"></div>
              </div>
            </template>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import {
  Search,
  NavBar,
  Tab,
  Tabs,
  List,
  PullRefresh,
  Cell,
  Image as VanImage,
  Empty,
  Button,
  Loading
} from "vant";

import { analysislist } from "@api/analysis";
import moment from "moment";


export default {
  name: "analysislist",
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      value: "",
      loading:true,
      pageNo:1
    };
  },
  components: {
    [Search.name]: Search,
    [NavBar.name]: NavBar,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [VanImage.name]: VanImage,
    [Empty.name]: Empty,
    [Button.name]: Button,
    [Loading.name]: Loading,

  },

  methods: {
    moment,
    onLoad() {
      this.loading = false;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.ajax_getAnalysisList();
      this.refreshing = false;
    },
    //获取体质报告列表
    ajax_getAnalysisList() {
      const pageNo = this.pageNo
      analysislist({pageNo:pageNo,pageSize:1}).then((res) => {
        if (res.respCode == 200) {
          this.list = res.result.list;
          this.loading = false
          this.pageNo ++
        }
      });
    },
  },
  mounted() {
    this.ajax_getAnalysisList();
  },
};
</script>
<style lang="less" scope>
.van-tabs {
  padding: 10px 0;
}
.reporttitle {
  line-height: 30px;
  padding: 15px 15px 0 15px;
}
</style>